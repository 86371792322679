import React from "react"
import { Link } from "gatsby"
import BlankLayout from "../components/blank-layout"
import PageSection from '../components/page-section'

const Sorry = ({ data, location }) => {
  return (
    <BlankLayout location={location} pageTitle="Sorry" bodyClass="page-subscribe">

      <PageSection title="" className={"red curved-top light-text"} />

      <PageSection>        
        This feature is available on Home Subscriptions only.
      </PageSection>

    </BlankLayout>
  )
}

export default Sorry